.advantages-1 {
    padding-top: 6rem;
    height: 106rem;
    position: relative;

    > .page-container {
        display: flex;
        flex-direction: column;
        height: 100%;
    }

    &__cloud {
        z-index: 1;

        height: 37rem;
    }

    &__title {
        margin-bottom: 1rem;
    }
    &__subtitle {
        font-weight: 500;
        color: #FF9B00;
    }

    &__items {
        flex-grow: 1;
        position: relative;
        z-index: 2;
        &-mobile {
            display: none;
        }
    }

    &__items {
        .accordion {
            &-item {
                &__header {
                    width: 27rem;
                    height: 6rem;

                    background: #FFFFFF;
                    box-shadow: 0 .4rem 1.3rem rgba(0, 0, 0, 0.11);
                    border-radius: 4.2rem;

                    font-size: 1.6rem;
                    line-height: 206%;
                    color: #1F1F1F;

                    padding-left: 8rem;
                    padding-right: 1rem;

                    display: flex;
                    align-items: center;

                    position: relative;
                    cursor: pointer;
                }
                &__icon {
                    width: 6rem;
                    height: 6rem;
                    border-radius: 50%;
                    background: #FFFFFF;
                    box-shadow: .2rem 0 .7rem rgba(0, 0, 0, 0.11);

                    display: flex;
                    align-items: center;
                    justify-content: center;

                    position: absolute;
                    left: 0;
                    top: 0;
                }
                &__panel {
                    display: none;
                    width: 40rem;

                    background: #FFFFFF;
                    box-shadow: 0 .4rem 1.3rem rgba(0, 0, 0, 0.11);
                    border-radius: 0 1.6rem 1.6rem 1.6rem;

                    font-size: 1.6rem;
                    line-height: 206%;
                    color: #1F1F1F;

                    padding: 2rem 4rem;

                    position: absolute;
                    left: 3rem;
                    top: 100%;
                    z-index: 1;
                }

                position: absolute;

                $start-offset: 11rem;
                &-1 {
                    top: $start-offset;
                    left: 16rem;
                }
                &-2 {
                    top: $start-offset + 6rem + 3rem;
                    left: 12rem;
                }
                &-3 {
                    top: $start-offset + 6*2rem + 3*2rem;
                    left: 8rem;
                }
                &-4 {
                    top: $start-offset + 6*3rem + 3*3rem;
                    left: 4rem;
                }
                &-5 {
                    top: $start-offset;
                    right: 8rem;
                }
                &-6 {
                    top: $start-offset + 6rem + 3rem;
                    right: 4rem;
                }
                &-7 {
                    top: $start-offset + 6*2rem + 3*2rem;
                    right: 0rem;
                }

                &-5,
                &-6,
                &-7 {
                    .accordion-item__panel {
                        left: auto;
                        right: 3rem;
                        border-radius: 1.6rem 0 1.6rem 1.6rem;
                    }
                }
            }
        }
    }
    &__image {
        width: 151.5rem;
        max-width: none;
        height: 96rem;

        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);

        z-index: -1;
    }
}
.advantages-1-card {
    max-width: 91rem;
    margin: 0 auto;
    &__content {
        font-size: 1.6rem;
        line-height: 206%;
        color: #1F1F1F;

        background: #FFFFFF;
        box-shadow: 0 .4rem 1.7rem rgba(0, 0, 0, 0.08);
        border-radius: .8rem;

        text-align: center;

        padding: 4rem 6rem 5rem;
    }


    position: relative;
    z-index: 1;
    &:after {
        content: '';

        width: 96.5%;
        height: 2rem;

        background: #FFFFFF;
        box-shadow: 0 .4rem 1.7rem rgba(0, 0, 0, 0.08);
        border-radius: .8rem;

        position: absolute;
        bottom: -1rem;
        left: 3.5/2 * 1%;

        z-index: -1;
    }
}

@media screen and (max-width: 1240px) {
    .advantages-1 {
        height: 130rem;
        &__image {

        }

        &__items {
            .accordion {
                &-item {
                    $items-left-start-offset: 8rem;
                    &-1 {
                        top: $items-left-start-offset;
                        left: auto;
                        right: 16rem;
                    }
                    &-2 {
                        top: $items-left-start-offset + 6rem + 3rem;
                        left: auto;
                        right: 12rem;
                    }
                    &-3 {
                        top: $items-left-start-offset + 6*2rem + 3*2rem;
                        left: auto;
                        right: 8rem;
                    }
                    &-4 {
                        top: $items-left-start-offset + 6*3rem + 3*3rem;
                        left: auto;
                        right: 4rem;
                    }
                    $items-right-start-offset: 5rem + 6*4rem + 3*4rem + 8rem;
                    &-5 {
                        top: $items-right-start-offset;
                        right: auto;
                        left: 8rem;
                    }
                    &-6 {
                        top: $items-right-start-offset + 6rem + 3rem;
                        right: auto;
                        left: 4rem;
                    }
                    &-7 {
                        top: $items-right-start-offset + 6*2rem + 3*2rem;
                        right: auto;
                        left: 0;
                    }

                    &-1,
                    &-2,
                    &-3,
                    &-4 {
                        .accordion-item__panel {
                            left: auto;
                            right: 3rem;
                            border-radius: 1.6rem 0 1.6rem 1.6rem;
                        }
                    }

                    &-5,
                    &-6,
                    &-7 {
                        .accordion-item__panel {
                            right: auto;
                            left: 3rem;
                            border-radius: 0 1.6rem 1.6rem 1.6rem;
                        }
                    }
                }
            }
        }
    }
}
@media screen and (max-width: 768px) {
    .advantages-1 {
        height: auto;
        padding-bottom: 45rem;
        &__items {
            display: none;
            &-mobile {
                display: block;
            }

            padding-top: 5rem;
            .accordion-item {
                position: static;
                &__header {
                    width: 100%;
                }
                &__panel {
                    position: static;
                    margin-left: 3rem;
                    margin-right: 3rem;
                    width: calc(100% - 6rem);
                    border-radius: 0 0 1.6rem 1.6rem !important;
                }

                margin-bottom: 1rem;
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
        &__image {
            $advantages-1-image-koef: 1.85;
            width: 151.5rem / $advantages-1-image-koef;
            height: 96rem / $advantages-1-image-koef;
        }
    }
}
@media screen and (max-width: 576px) {
    .advantages-1 {
        padding-bottom: 4rem;
        &__content {
            font-size: 1.4rem;
        }
        &__title {
        }
        &__subtitle {

        }

        &__items {
            .accordion-item {
                &__header {
                    font-size: 1.4rem;
                }
                &__panel {
                    font-size: 1.2rem;
                    padding: 1rem 2rem;
                }
            }
        }

        &__image {
            display: none;
        }
    }
    .advantages-1-card {
        &__content {
            font-size: 1.4rem;
            padding: 3rem 4rem 3rem;
        }
    }
}
