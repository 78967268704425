.background-image {
    position: absolute;
    left: 0;
    top: 0;

    width: 100%;
    height: 100%;

    background-image: url('../img/background-1.jpg');
    background-repeat: repeat-x;
    background-size: auto 100%;
    background-position: center;

    z-index: -2;
}
