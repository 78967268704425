.button {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 28.5rem;
    height: 8.3rem;

    position: relative;
    z-index: 1;
    &__layer {
        width: 100%;
        height: 7.7rem;
        border-radius: 5.4rem;

        position: absolute;
        left: 0;
        &_top {
            top: 0;

            background-image: url(../img/button-texture.jpg);
            background-size: 100% 100%;
            background-repeat: no-repeat;
            background-position: center;

            z-index: -1;
        }
        &_bottom {
            bottom: 0;

            background: #DC8600;
            z-index: -2;
        }
    }
    &__text {
        display: block;

        font-family: 'Lora', serif;
        font-weight: 700;
        font-size: 1.5rem;
        line-height: 206%;
        color: #714400;
        text-transform: uppercase;

        margin-top: -.5em;
    }
}

.button_animated {
    text-rendering: auto;
    letter-spacing: normal;
    word-spacing: normal;
    text-transform: none;
    text-indent: 0px;
    text-shadow: none;
    position: relative;
    top: 0;
    overflow: hidden;
    cursor: pointer;
    border-radius:4px;
    &:after {
        position: absolute;
        top: -150px;
        left: -40px;
        width: 30px;
        height: 300px;
        margin-left: 60px;
        content: '';
        transition: all .1s;
        transform: rotate(45deg);
        animation-name: slideme;
        animation-duration: 3s;
        animation-timing-function: ease-in-out;
        animation-delay: .05s;
        animation-iteration-count: infinite;
        background: linear-gradient(to bottom, rgba(229, 172, 142, 0), rgba(255,255,255,0.8) 50%, rgba(229, 172, 142, 0));
    }
}
@-webkit-keyframes slideme { 0% { left: -30px; margin-left: 0px; }
    30% { left: 110%; margin-left: 80px; }
    100% { left: 110%; margin-left: 80px; }
}

.button-little {
    width: 15.5rem;
    height: 4.5rem;
    .button {
        &__layer {
            height: 4.2rem;
            &_top {

            }
            &_bottom {

            }
        }
        &__text {
            font-size: 1rem;
            margin-top: -.25em;
        }
    }
}

@media screen and (max-width: 576px) {
    .button {
        width: 25rem;
        height: 7rem;
        &__layer {
            height: 6.4rem;
        }
        &__text {
            font-size: 1.3rem;
            margin-top: -.5em;
        }
    }
    .button-little {
        width: 13rem;
        height: 4rem;
        .button {
            &__layer {
                height: 3.7rem;
                &_top {

                }
                &_bottom {

                }
            }
            &__text {
                font-size: .9rem;
                margin-top: -.25em;
            }
        }
    }
}
