.h1-title {
    font-family: 'Lora', serif;
    font-size: 2.8rem;
    line-height: 206%;
    text-transform: uppercase;
    color: #1F1F1F;
    .bold {
        font-weight: 700;
    }
    .orange {
        color: #FF9B00;
    }
}
.h2-title {
    font-family: 'Lora', serif;
    font-weight: 700;
    font-size: 2.2rem;
    line-height: 206%;
    text-transform: uppercase;
    color: #3F3F3F;
    .orange {
        color: #FF9B00;
    }
    &_centered {
        display: flex;
        justify-content: center;

        position: relative;
        padding: 0 9rem;
        &:before {
            content: '';

            width: 8rem;
            height: .1rem;
            background: #A7A7A7;

            position: absolute;
            left: 0;
            top: calc(50% - .5rem);
        }
        &:after {
            content: '';

            width: 8rem;
            height: .1rem;
            background: #A7A7A7;

            position: absolute;
            right: 0;
            top: calc(50% - .5rem);
        }
    }
}
.subtitle {
    font-size: 1.6rem;
    line-height: 206%;
    color: #1F1F1F;

    padding-left: 2.7rem + 5.2rem;
    position: relative;
    &:before {
        content: '';

        width: 5.2rem;
        height: .2rem;
        background: #FBBF62;

        position: absolute;
        left: 0;
        top: 1em;
    }
}

@media screen and (max-width: 992px) {
    .h1-title {
        font-size: 2.4rem;
    }
}
@media screen and (max-width: 768px) {
    .h1-title {
        br {
            display: none;
        }
    }
    .h2-title {
        &_centered {
            padding: 0;
            &:before,
            &:after {
                display: none;
            }
        }
    }
}
@media screen and (max-width: 576px) {
    .h1-title {
        font-size: 1.8rem;
        br {
            display: none;
        }
    }
    .h2-title {
        font-size: 1.8rem;
        br {
            display: none;
        }
        &_centered {
            text-align: center;
        }
    }
    .subtitle {
        font-size: 1.3rem;
        padding-left: 1.5rem + 3rem;
        &:before {
            width: 3rem;
            top: .9em;
        }
    }
}
