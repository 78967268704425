.first-screen {

    padding-top: 5rem;
    position: relative;

    &__content {
        position: relative;
        z-index: 1;

        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }
    &__title {
        margin-bottom: 1rem;
    }
    &__subtitle {
        margin-bottom: 3rem;

        font-family: Lora, serif;
        font-size: 2.2rem;
        line-height: 206%;
        text-transform: uppercase;
        color: #1F1F1F;
        .bold {
            font-weight: 700
        }
        .orange {
            color: #FF9B00;
        }
    }
    &__advantages {
        display: flex;
        margin-bottom: 2.5rem;
    }
    &__button {
        margin-bottom: 2.4rem;

        position: relative;
        &:after {
            content: '';

            width: 9rem;
            height: 9rem;

            background-image: url(../img/line-2.svg);
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;

            position: absolute;
            left: calc(100%);
            top: 3.5rem;
        }
    }

    &__image {
        max-width: none;

        $image-koef: 1.1;

        width: 89rem / $image-koef;
        height: 57rem / $image-koef;

        position: absolute;
        bottom: 5rem;
        right: -15rem;

        z-index: -1;
    }
}
.first-screen-advantage {
    background: #FFFFFF;
    box-shadow: 0 .4rem 1rem rgba(0, 0, 0, 0.08);
    border-radius: 1.2rem;

    width: 16rem;
    min-height: 16rem;

    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1.5rem 1.75rem;

    margin-right: 2.1rem;
    &:last-child {
        margin-right: 0;
    }

    &__image {
        height: 7rem;
        flex: 0 0 auto;
        display: flex;
        align-items: flex-end;

        margin-bottom: 1rem;
        img {

        }
    }
    &__text {
        font-size: 1rem;
        line-height: 206%;
        color: #1F1F1F;
    }
}
.first-screen-present {
    display: flex;
    align-items: center;

    padding-left: 2.4rem;
    &__image {
        margin-right: 2rem;
    }
    &__text {
        font-size: 1.3rem;
        line-height: 206%;
        color: #1F1F1F;
        span {
            font-weight: 500;
        }
    }
}

@media screen and (max-width: 1240px) {
    .first-screen {
        &__image {
            $image-koef: 1.3;

            width: 89rem / $image-koef;
            height: 57rem / $image-koef;

            bottom: 0;
            right: -15rem;
        }
    }
}
@media screen and (max-width: 992px) {
    .first-screen {
        padding-bottom: 20rem;
        &__image {
            $image-koef: 1.3;

            width: 89rem / $image-koef;
            height: 57rem / $image-koef;

            bottom: 0;
            right: -15rem;
        }
    }
}
@media screen and (max-width: 768px) {
    .first-screen {
        padding-bottom: 22.5rem;
        &__image {
            $image-koef: 1.3;

            width: 89rem / $image-koef;
            height: 57rem / $image-koef;

            bottom: 0;
            right: -3rem;
        }
    }
}
@media screen and (max-width: 576px) {
    .first-screen {
        padding-top: 2rem;
        padding-bottom: 20rem;
        &__title {
            text-align: center;
        }
        &__advantages {
            flex-flow: row wrap;
            justify-content: center;
        }
        &__button {
            text-align: center;
        }
        &__subtitle {
            font-size: 1.6rem;
            text-align: center;
        }
        &__button {
            &:after {
                $button-after-koef: 1.25;
                width: 9rem / $button-after-koef;
                height: 9rem / $button-after-koef;
                left: calc(100% - 1rem);
                top: 3rem;
            }
        }

        &__image {
            $image-koef: 2;

            width: 89rem / $image-koef;
            height: 57rem / $image-koef;
        }
        &__title {
            max-width: 38rem;
        }
    }
    .first-screen-advantage {
        margin-right: 1rem;
        margin-left: 1rem;
        margin-bottom: 2rem;
        &:last-child {
            margin-bottom: 0;
        }
    }
    .first-screen-present {
        justify-content: center;
        padding-left: 0;
        &__text {
            font-size: 1.1rem;
        }
    }
}
@media screen and (max-width: 450px) {
    .first-screen {
        padding-bottom: 14rem;
        &__image {
            right: -12rem;
        }
    }
}
@media screen and (max-width: 350px) {
    .first-screen {
        &__button {
            &:after {
                display: none;
            }
        }
    }
}
