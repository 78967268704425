.phone {
    font-weight: 500;
    font-size: 1.8rem;
    line-height: 206%;
    color: #1F1F1F;
    white-space: nowrap;
}

@media screen and (max-width: 576px) {
    .phone {
        font-size: 1.4rem;
    }
}
