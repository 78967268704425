@import "../mixins/media";

.header {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;

    padding-top: 2.5rem;

    position: relative;
    z-index: 1;

    &__logo {
        width: 22.5rem;
        height: 13rem;

        margin-top: -2.5rem - 1rem;

        display: flex;
        align-items: center;
        justify-content: center;

        position: relative;

        margin-right: 3rem;
        img {
            width: 100%;
        }
        .logo {
            width: 11/22.5 * 100%;
        }
        &:before {
            content: '';

            width: 25/22.5 * 100%;
            height: 15/13 * 100%;

            position: absolute;
            top: (25 - 22.5) / 2 * (-1rem);
            left: (15 - 13) / 2 * (-1rem);

            background-image: url('../img/panel-1.svg');
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;

            z-index: -1;
        }
    }
    &__title {
        font-weight: 500;
        font-size: 1.3rem;
        line-height: 206%;
        color: #1F1F1F;

        &_pre-dot {
            position: relative;
            &:before {
                content: '';

                width: 1.2rem;
                height: 1.2rem;

                background-image: url('../img/dot-green.svg');
                background-size: contain;
                background-repeat: no-repeat;
                background-position: center;

                position: absolute;
                right: calc(100% + 1.2rem);
                top: .6em;
            }
        }
    }

    &__right {
        margin-left: auto;

        display: flex;
        align-items: flex-start;
    }
    &__soc-links {
        &-title {
            margin-bottom: 1.5rem;
        }
        &-list {
            list-style: none;
            padding-left: 0;
            display: flex;

            margin: 0;
        }
        &-item {
            margin: 0;
            margin-right: 1.7rem;
            &:last-child {
                margin-right: 0;
            }
        }
        &-link {

        }
    }
    &__contacts {
        display: flex;
        flex-direction: column;
        align-items: flex-end;

        margin-left: 15rem;
    }
    &__phone {
        margin-bottom: .5rem;
    }
    &__button {
        margin-right: -.5rem;
    }
}

@media screen and (max-width: 1240px) {
    .header {
        &__right {
            align-items: flex-start;
        }
        &__soc-links {
            margin-right: 2.5rem;
            padding-top: .5rem;
            &-title {
                display: none;
            }
        }
        &__contacts {
            margin-left: 0;
            &-title {
                display: none;
            }
        }
    }
}
@media screen and (max-width: 992px) {
    .header {
        &__right {
            flex-direction: column-reverse;
            align-items: flex-end;
        }
        &__contacts {
            margin-bottom: 1rem;
        }
        &__soc-links {
            margin-right: 0;
        }
    }
}
@media screen and (max-width: 768px) {
    .header {
        padding-top: 1.5rem;
        $header-logo-koef: 1.25;
        &__logo {
            width: 22.5rem / $header-logo-koef;
            height: 13rem / $header-logo-koef;

            margin-top: -1.5rem;
            margin-right: 1.5rem;
            &:before {
                top: (25 - 22.5) / 2 * (-1rem/$header-logo-koef);
                left: (15 - 13) / 2 * (-1rem/$header-logo-koef);
            }
        }
        &__tagline {
            font-size: 1.1rem;
            margin-top: 1rem;
            margin-right: 3rem;
        }
    }
}
@media screen and (max-width: 576px) {
    .header {
        flex-flow: row wrap;
        justify-content: center;
        &__right {
            flex-basis: 100%;
            flex-direction: row;
            justify-content: center;
            align-items: center;
        }
        $header-logo-koef: 1.6;
        &__logo {
            width: 22.5rem / $header-logo-koef;
            height: 13rem / $header-logo-koef;

            margin-top: -1.5rem;
            margin-right: 1.5rem;
            .logo {
                width: 60%;
            }
            &:before {
                top: (25 - 22.5) / 2 * (-1rem/$header-logo-koef);
                left: (15 - 13) / 2 * (-1rem/$header-logo-koef);
            }
        }
        &__tagline {
            flex-basis: calc(100% - 18rem - 1.5rem);
            margin-right: 0;
            margin-top: .25rem;
            max-width: 18.5rem;
            font-size: 1rem;
            br {
                display: none;
            }
        }
        &__soc-links {
            padding-top: 0;
            margin-right: 2.5rem;
        }
    }
}
@media screen and (max-width: 400px) {
    .header {
        &__tagline {
            margin-bottom: 1rem;
            flex-basis: 100%;
        }
    }
}
