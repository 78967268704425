.cloud {
    overflow: hidden;

    position: absolute;
    left: 0;
    bottom: 0;

    width: 100%;
    height: 29rem;

    background-image: url('../img/cloud.png');
    background-size: auto 65rem;
    background-position: center top;
    background-repeat: repeat-x;

    &__image {
        display: none;
        height: 65rem;
    }
}
