@import "../../local_modules/normalize.css/normalize";
@import "../mixins/media";

*,
*::before,
*::after {
    box-sizing: inherit;
}

html,
body {
    overflow-x: hidden;
}

html {
    box-sizing: border-box;
    font-size: 10px;
}

body {
    margin: 0;
    font-family: 'Gotham Pro', sans-serif;
}

img {
    max-width: 100%;
}

button {
    padding: 0;
    outline: none;
    border: none;
    background: none;
    cursor: pointer;
}

a {
    font-family: inherit;
    text-decoration: none;
}

h1,h2,h3,h4,h5,h6,p {
    margin: 0;
}

ul {
    list-style: none;
    padding-left: 0;
    margin: 0;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus
input:-webkit-autofill,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    border: 0;
    -webkit-text-fill-color: #ссс;
    -webkit-box-shadow: 0 0 0px 1000px transparent inset;
    transition: background-color 5000s ease-in-out 0s;
    background: -webkit-linear-gradient(top,  rgba(255,255,255,0) 0%,rgba(0,174,255,0.04) 50%,rgba(255,255,255,0) 51%,rgba(0,174,255,0.03) 100%);
}

.page-container {
    max-width: 1240px;
    padding: 0 20px;
    margin: 0 auto;
}


.jquery-modal.blocker {
    z-index: 999;
}

@include media-custom('1240px') {
    .page-container {
        max-width: 952px;
    }
}
@include media-custom('992px') {
    .page-container {
        max-width: 728px;
    }
}

@include media-custom('768px') {
    .page-container {
        width: 100%;
    }
}
@include media-custom('576px') {
    .page-container {
        padding: 0 10px;
    }
}

