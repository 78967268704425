.header-wrapper {
    position: relative;
    &__cloud {
        bottom: 4rem;
    }
    &__lines {
        height: calc(100% - 5rem) !important;
        background-size: 15rem auto !important;
        z-index: -1 !important;
    }
}
@media screen and (max-width: 1240px) {
    .header-wrapper {
        position: relative;
        &__cloud {
            bottom: -2rem;
        }
        &__lines {
            height: 100% !important;
        }
    }
}
@media screen and (max-width: 576px) {
    .header-wrapper {
        &__cloud {
            bottom: 0;
            background-size: auto 32.5rem;
            height: 14.5rem;
        }
    }
}
