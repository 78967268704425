.popup {
  padding: 0;
  z-index: 95;
  max-width: 45rem;

  &__title {
    font-weight: 700;
    font-size: 2.2rem;
    line-height: 43/22 * 1rem;
    color: #656565;
    margin-bottom: 1.8rem;
  }
  &__text {
    font-size: 1.6rem;
    line-height: 43/16 * 1rem;
    color: #656565;
    margin-bottom: 3rem;
  }
  &__form {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 5rem 7.5rem;
  }
  &__input {
    margin-bottom: 2.5rem;
    width: 100%;
  }
  &__button {
      margin-bottom: 2rem;
    &:disabled {
      opacity: .5;
      cursor: not-allowed;
    }

    width: 100%;
    //height: 9.75rem - 3rem;
    //.button-large {
    //  &__layer {
    //    &_top {
    //      height: 9.15rem - 3rem;
    //    }
    //    &_bottom {
    //        height: 9.15rem - 3rem;
    //    }
    //  }
    //}
  }
    &__privacy-policy {
        width: 90%;

        font-size: 1rem;
        line-height: 206%;
        color: #AFAFAF;
    }

  &__error-message {
    width: 100%;
    font-size: 1.6rem;
    text-align: center;
    margin-top: -1rem;
      margin-bottom: 1rem;
    color: red;
  }
}
.modal {
  display: none;
}
.modal.is-open {
  display: block;
}

@media screen and (max-width: 576px) {
  .popup {
    width: 100% !important;
    max-width: 33rem !important;
    padding: 1rem 2rem !important;
    &__title {
      font-size: 1.6rem;
        text-align: center;
    }
    &__text {
        font-size: 1.2rem;
        text-align: center;
    }
    &__content {
      width: 100%;
      max-width: 35rem;
    }
    &__form {
      padding: 5rem 0rem;
    }
      &__input {
          margin-bottom: 1.25rem;
          padding: 0 1.25rem;
          .input {
              &__icon-wrapper {
                  margin-right: 1rem;
              }
          }
      }
    &__button {
      .button {
        width: 100%;
      }
    }

    &__success-message {
      font-size: 1.6rem;
      padding: 10rem 5rem;
    }
    &__error-message {
      font-size: 1.2rem;
    }
  }
}
@media screen and (max-width: 400px) {
  .popup {
    &__input {
      width: 100%;
    }
    &__button {
      width: 100%;
    }

    &__text {
      br {
        display: none;
      }
    }
  }
}
